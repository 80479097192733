const ESCAPE_CHARS = {
  lt: '<',
  gt: '>',
  quot: '"',
  apos: "'",
  amp: '&',
  hellip: '...',
}

export const unescapeHTML = (str: string) => {
  if (!str) return null
  return str.replace(/\&([^;]+);/g, (entity, entityCode) => {
    let match

    if (entityCode in ESCAPE_CHARS) {
      return ESCAPE_CHARS[entityCode]
    } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
      return String.fromCharCode(parseInt(match[1], 16))
    } else if ((match = entityCode.match(/^#(\d+)$/))) {
      return String.fromCharCode(~~match[1])
    } else {
      return entity
    }
  })
}
