'use client'
import { useEffect, useState } from 'react'

export const useIntersectionObserver = (
  containerRef,
  options = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  }
) => {
  const [isVisible, setIsVisible] = useState(false)
  const [hasIntersected, setHasIntersected] = useState(false)

  const callback = entries => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
    if (!hasIntersected && entry.isIntersecting) setHasIntersected(true)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options)
    if (containerRef.current) observer.observe(containerRef.current)

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current)
    }
  }, [containerRef, options])

  return { isVisible, hasIntersected }
}
