import { getFeBaseUrl } from './get-fe-base-url.helpers'

export const stripFrontendUrl = (url: string) => {
  if (!url) return ''

  // List of domains to replace
  const domains = [
    'riksjatravel.nl',
    'www.riksjatravel.nl',
    'test.riksjatravel.nl',
    'uat.riksjatravel.nl',
    'wp.riksjatravel.nl',
    'test-wp.riksjatravel.nl',
    'uat-wp.riksjatravel.nl',
    'erlebe.de',
    'www.erlebe.de',
    'test.erlebe.de',
    'uat.erlebe.de',
    'wp.erlebe.de',
    'test-wp.erlebe.de',
    'uat-wp.erlebe.de',
    'rickshawtravel.co.uk',
    'rickshawtravel.dev',
    'www.rickshawtravel.co.uk',
    'test.rickshawtravel.dev',
    'uat.rickshawtravel.dev',
    'wp.rickshawtravel.co.uk',
    'test-wp.rickshaw.co.uk',
    'uat-wp.rickshawtravel.co.uk',
    'riksja.test.acato.nl',
    'riksja.acato.nl',
    'test.riksja.acato.nl',
    'erlebe.acato.nl',
    'rickshaw.acato.nl',
  ]

  // Create a regex pattern using the list of domains
  const pattern = new RegExp(
    '\\b(?:https?|ftp)://(?:' + domains.join('|') + ')\\b',
    'g'
  )

  const replacement = getFeBaseUrl()

  const result = url.replace(pattern, replacement)
  const pathOnly = result.replace(replacement, '')

  return pathOnly
}
