'use client'
import React, { ErrorInfo, ReactNode } from 'react'

import { dispatchRollbarError } from '@/utilities/helpers/dispatchRollbarError.helpers'

interface ErrorBoundaryProps {
  children: ReactNode
  fallback?: ReactNode
  errorMessage?: string
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
    }
  }

  // Update state when an error is thrown
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error } // Update state with error
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    dispatchRollbarError(
      `Caught by error boundary:  ${error?.message} ${
        this.props?.errorMessage ? '| ' + this.props?.errorMessage : null
      }`
    )
  }

  render() {
    if (this.state.hasError) {
      // Check if a custom fallback UI is provided via props
      if (this.props.fallback) {
        return this.props.fallback
      }
      return null
    }

    // Render the child components if no error has been encountered
    return this.props.children
  }
}

export default ErrorBoundary
