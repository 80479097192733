import { useMemo } from 'react'
import styles from './ra-loader.module.scss'

interface RaLoaderProps {
  color?: 'primary' | 'light'
}

export const RaLoader = ({ color = 'primary' }: RaLoaderProps) => {
  const loadingColor = useMemo(() => {
    if (color === 'light') {
      return {
        normal: 'var(--color-white)',
        light: 'var(--color-white)',
      }
    }

    return {
      normal: 'var(--color-primary)',
      light: 'var(--color-primary-300)',
    }
  }, [color])

  return (
    <div
      style={
        {
          '--loader-color-normal': loadingColor.normal,
          '--loader-color-light': loadingColor.light,
        } as React.CSSProperties
      }
      className={styles['loader']}></div>
  )
}
