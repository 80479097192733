import { HTMLAttributes, memo } from 'react'
import clsx from 'clsx'

import styles from './ra-typography.module.scss'

interface IRaTypography
  extends HTMLAttributes<HTMLDivElement | HTMLParagraphElement> {
  component?: 'p' | 'div' | 'span'
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | number
  weight?: 'regular' | 'semibold' | 'bold'
  fontFamily?: 'body' | 'heading'
  align?: 'left' | 'center' | 'right'
  dropShadow?: boolean
  color?: 'primary' | 'dark' | 'light' | 'family' | 'off-black'
  customClasses?: any
  spacingX?: 1 | 2 | 3 | 4 | 5 | 6
  spacingY?: 1 | 2 | 3 | 4 | 5 | 6
  spacing?: 1 | 2 | 3 | 4 | 5 | 6
}

export const RaTypography = memo(
  ({
    component: Component = 'p',
    size = 'm',
    weight = 'regular',
    dropShadow = false,
    fontFamily = 'body',
    align = 'left',
    color = 'dark',
    spacing,
    customClasses,
    spacingX,
    spacingY,
    ...props
  }: IRaTypography) => {
    return (
      <Component
        className={clsx(
          styles['ra-typography'],
          size &&
            typeof size === 'string' &&
            styles[`ra-typography--size-${size}`],
          size &&
            typeof size === 'number' &&
            styles[`ra-typography--custom-size`],
          weight && styles[`ra-typography--weight-${weight}`],
          dropShadow && styles['ra-typography--shadow'],
          fontFamily === 'heading' && styles['ra-typography--heading'],
          customClasses && customClasses
        )}
        style={
          {
            color: `var(--color-body-${color})`,
            textAlign: align,
            '--ra-typography-spacing-x':
              spacingX && `var(--ra-spacing-${spacingX})`,
            '--ra-typography-spacing-y':
              spacingY && `var(--ra-spacing-${spacingY})`,
            '--ra-typography-spacing':
              spacing && `var(--ra-spacing-${spacing})`,
            '--ra-typography-custom-size':
              size && typeof size === 'number' && `${size / 16}rem`,
            ...props.style,
          } as React.CSSProperties
        }
        {...props}
      />
    )
  }
)
