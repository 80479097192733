import { HTMLAttributes, memo } from 'react'
import clsx from 'clsx'

import styles from './ra-heading.module.scss'

interface IRaHeading extends HTMLAttributes<HTMLHeadingElement> {
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  size?: '' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  color?: 'light' | 'dark' | 'primary'
  spacingX?: 1 | 2 | 3 | 4 | 5 | 6
  spacingY?: 1 | 2 | 3 | 4 | 5 | 6
  spacing?: 1 | 2 | 3 | 4 | 5 | 6
  noPadding?: boolean
  alignment?: 'left' | 'center' | 'right'
  dropShadow?: boolean
}

export const RaHeading = memo(
  ({
    component: Component = 'h1',
    size,
    color = 'primary',
    dropShadow = false,
    noPadding = false,
    alignment = 'left',
    spacing,
    spacingX,
    spacingY,
    ...props
  }: IRaHeading) => {
    return (
      <Component
        {...props}
        style={
          {
            '--ra-heading-spacing-x':
              spacingX && `var(--ra-spacing-${spacingX})`,
            '--ra-heading-spacing-y':
              spacingY && `var(--ra-spacing-${spacingY})`,
            '--ra-heading-spacing': spacing && `var(--ra-spacing-${spacing})`,
            ...props.style,
          } as React.CSSProperties
        }
        className={clsx(
          styles['ra-heading'],
          size && styles[`ra-heading--size-${size}`],
          color && styles[`ra-heading--color-${color}`],
          dropShadow && styles[`ra-heading--shadow`],
          noPadding && styles[`ra-heading--no-padding`],
          alignment && styles[`ra-heading--align-${alignment}`],
          props['className']
        )}
      />
    )
  }
)
