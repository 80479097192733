export const Loop = <C = any, R = unknown>(collection: C[], callback: (item: ArrayElement<C[]>, index: number) => R) => {
	if (!collection?.length || !callback) return []

	const len = collection.length
	let n = 0
	const result: R[] = []

	for (n; n < len; n++) {
		const temp = callback(collection[n], n)

		if (temp) result.push(temp)
	}

	return result
}
