'use client'

export const setLocalStorage = (key: string, value: any) => {
  if (typeof window === 'undefined') return null
  if (!localStorage) return null
  localStorage.setItem(key, JSON.stringify(value))
}

export const getFromLocalStorage = (key: string) => {
  if (typeof window === 'undefined') return null
  if (!localStorage) return null
  const value = localStorage.getItem(key)
  if (!value) return null
  return JSON.parse(value)
}

export const removeFromLocalStorage = (key: string) => {
  if (typeof window === 'undefined') return null
  if (!localStorage) return null
  localStorage.removeItem(key)
}
