import React, { memo } from 'react'
import clsx from 'clsx'

import styles from './ra-container.module.scss'

interface RaContainerProps {
  children: React.ReactNode
  maxWidth?: number
  containerClasses?: any
}

export const RaContainer = memo(
  ({ children, maxWidth, containerClasses }: RaContainerProps) => {
    return (
      <div
        className={clsx(
          styles['ra-container'],
          maxWidth && styles['ra-container--max-width'],
          containerClasses && containerClasses
        )}
        style={
          { '--max-container-width': `${maxWidth}px` } as React.CSSProperties
        }>
        {children}
      </div>
    )
  }
)

RaContainer.displayName = 'RaContainer'
