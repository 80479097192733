import { ButtonHTMLAttributes, Fragment, memo } from 'react'
import clsx from 'clsx'
import Link from 'next/link'

import { RaIcon, RaTypography } from '@components/core/index.core'
import { RaLoader } from '@components/features/RaLoader/RaLoader'
import { AcfLinkItemProps } from '@typings'
import { stripFrontendUrl } from '@utilities/helpers/strip-fe-url.helpers'

import styles from './ra-button.module.scss'

interface RaButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string | React.ReactNode
  link?: AcfLinkItemProps
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  fullWidth?: boolean
  icon?: string | React.ReactNode
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  travelplan?: boolean
  small?: boolean
  variant?:
    | 'primary'
    | 'secondary-light'
    | 'secondary-dark'
    | 'secondary-hero'
    | 'secondary-footer'
    | 'text-primary'
    | 'text-light'
    | 'text-dark'
    | 'icon'
  textColor?: 'primary' | 'light' | 'dark'
  customColor?: string
  ariaLabel?: string
  loading?: boolean
  retainFullUrl?: boolean
  tooltip?: string
}

const getButtonProps = ({
  label,
  ariaLabel,
  fullWidth,
  startIcon,
  endIcon,
  color,
  variant = 'primary',
  travelplan = false,
  small = false,
  loading,
  textColor,
  customColor,
  tooltip,
  ...buttonProps
}: RaButtonProps) => {
  return {
    ...buttonProps,
    ['aria-label']: !ariaLabel && typeof label === 'string' ? label : ariaLabel,
    className: clsx(
      styles['ra-button'],
      styles[`ra-button--${variant}`],
      travelplan && styles['ra-button--travelplan'],
      fullWidth && styles['ra-button--full-width'],
      small && styles['ra-button--small'],
      customColor && styles['ra-button--custom-color'],
      buttonProps.className
    ),
    style: {
      ...(textColor
        ? { '--ra-button-text-color': `var(--color-${textColor})` }
        : {}),
      ...(color ? { '--ra-button-color': `var(--color-${color})` } : {}),
      ...(customColor ? { '--ra-button-color': customColor } : {}),
      ...buttonProps?.style,
    },
  }
}

export const RaButton = memo(
  ({
    label,
    link,
    type = 'button',
    icon,
    startIcon,
    endIcon,
    textColor,
    fullWidth,
    ariaLabel = '',
    loading = false,
    retainFullUrl = false,
    tooltip,
    ...props
  }: RaButtonProps) => {
    const buttonProps = getButtonProps({
      label,
      ariaLabel,
      type,
      fullWidth,
      loading,
      ...props,
    })

    const Button = (
      <button
        {...buttonProps}
        disabled={buttonProps.disabled || loading}>
        {startIcon} {!icon && (label || link?.title)}
        {endIcon}
        {loading && <RaLoader color={'light'} />}
        {icon && typeof icon === 'string' && (
          <RaIcon
            icon={icon}
            color={textColor}
          />
        )}
        {icon && typeof icon !== 'string' && icon}
      </button>
    )

    if (link) {
      return (
        <Link
          target={link.target}
          prefetch
          title={link.title}
          href={retainFullUrl ? link.url : stripFrontendUrl(link.url)}
          tabIndex={-1}
          className={clsx(
            styles['ra-button__link'],
            fullWidth && styles['ra-button__link--fullwidth']
          )}>
          {Button}
        </Link>
      )
    }

    if (tooltip) {
      return (
        <div className={styles['ra-tooltip']}>
          {Button}
          {tooltip && (
            <div className={styles['ra-button__tooltip']}>
              <RaTypography
                component="span"
                size="xs"
                color="light">
                {tooltip}
              </RaTypography>
            </div>
          )}
        </div>
      )
    }
    return Button
  }
)
