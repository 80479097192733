import { env } from 'next-runtime-env'
import { isServer } from './is-server'
import { LOCAL_STORAGE } from '@constants/local-storage.constants'

export const getFeBaseUrl = () => {
  if (isServer) {
    return env('FE_BASE_URL') || process.env.FE_BASE_URL
  } else {
    const ls = localStorage.getItem(LOCAL_STORAGE.FE_URL)
    if (!ls) return ''
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE.FE_URL))
  }
}
